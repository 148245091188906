import { createSlice } from '@reduxjs/toolkit'
 
const { actions, reducer } = createSlice({
    name: 'globals',
    initialState: { 
        section: '',
        language: 'fr',
        imageViewerUrl: ''
    },
    reducers: {
        setSection: (state, action) => {
            state.section = action.payload
        },
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        setImageViewerUrl: (state, action) => {
            state.imageViewerUrl = action.payload;
        }
    },
});

export const { 
    setSection,
    setLanguage,
    setImageViewerUrl
} = actions;

export default reducer;