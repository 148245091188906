import axios from 'axios';

console.log('[ENV] ' + process.env.REACT_APP_ENV);

// Configurations
const config = {
    'local': {
        host: "localhost",
        port: 2000,
        api: "api"
    },
    'recette': {
        host: "serenitenaturelle.com",
        port: 443,
        api: "api"
    },
    'production': {
        host: "serenitenaturelle.com",
        port: 443,
        api: "api"
    }
}

// Extract data from config
export const host = config[process.env.REACT_APP_ENV]['host'];
export const port = config[process.env.REACT_APP_ENV]['port'];
export const urlApi = config[process.env.REACT_APP_ENV]['api'];

// Create axios instance
export const axiosInstance = axios.create();
axiosInstance.defaults.withCredentials = true;
axiosInstance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});
