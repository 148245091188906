import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Home from './pages/Home/Home';
import Command from './pages/Command/Command';
import Contacts from './pages/Contacts/Contacts';
import Admin from './pages/Admin/Admin';

import NavBar from './components/NavBar/NavBar';
import Footer from './components/Footer/Footer';
import ShoppingBag from './components/ShoppingBag/ShoppingBag';
import ImageViewer from './components/ImageViewer/ImageViewer';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div id="app">
      <NavBar />
      <Routes forceRefresh={false}>
        <Route path="/home" element={<Home />} />
        <Route path="/command" element={<Command />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
      <ToastContainer />
      <ShoppingBag />
      <ImageViewer />
    </div>
  );
}

export default App;
