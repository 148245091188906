import { urlApi, axiosInstance } from "./config";

const URL_AUTH = urlApi + '/auth';

class AuthAPI {

    constructor (instance, url) {
        this.instance = instance;
        this.url = url;
    }

    async login (
        username,
        password
    ) {
        return axiosInstance.post(this.url, {
            username: username,
            password: password
        });
    }
}

export const authAPI = new AuthAPI(axiosInstance, URL_AUTH);
