// Import modules
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Import components
import Loader from '../../../../components/Loader/Loader';

// Import store
import * as shoppingSelectors from '../../../../store/selectors/shopping';
import * as shoppingActions from '../../../../store/features/shopping';
import * as globalsActions from '../../../../store/features/globals';

// Import API
import { productAPI } from '../../../../api/ProductApi';

// Import constants
import { TOAST_PROPERTIES } from '../../../../constants/toasts';

// Import style
import './Details.scss';

const QUANTITIES = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Details () {

  const dispatch = useDispatch();
  const articles = useSelector(shoppingSelectors.getArticles);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Get all products
   */
  const getProducts = useCallback(() => {
    productAPI.getAll().then((res) => {
      const productList = res.data.reduce((acc, curr) => {
        acc[curr.name] = curr;
        return acc;
      }, {});
      setProducts(productList);
      setIsLoading(false);
    }).catch((err) => {
      toast.error("Erreur lors de la récupération des produits", TOAST_PROPERTIES);
      console.error(err);
    })
  }, []); 

  useEffect(() => {
    getProducts();
  }, [getProducts]);

  const total = useMemo(() => {
    return Object.keys(articles).reduce((acc, curr) => {
      if (!products[curr]) return acc;
      acc = acc + articles[curr].quantity * products[curr].price;
      return acc;
    }, 0);
  }, [articles, products]);

  const changeQuantity = (event, pProductName) => {
    event.preventDefault();
    dispatch(shoppingActions.setQuantity({
      name: pProductName, 
      quantity: parseInt(event.target.value)
    }));
  }
  
  const remove = (event, pProductId) => {
    event.preventDefault();
    dispatch(shoppingActions.remove(pProductId));
  }

  const handleOpenImage = (pProductName) => {
    dispatch(globalsActions.setImageViewerUrl(products[pProductName].largeImage));
  }
  
  return (
    <div id="details" className='center'>
      <h2>... détails de la commande</h2>
      <ul>
        {
          !isLoading && Object.keys(articles).map((name, index) => {
            return (
              <li key={index}>
                <img 
                  src={products[name].smallImage} 
                  onClick={() => handleOpenImage(name)}
                  alt='logo' />
                <div className='article-description'>
                  <div className='article-name'>{name}</div>
                  <div className='article-price'>{products[name].price}da</div>
                </div>
                <div className='article-quantity center'>
                  <InputGroup>
                    <Form.Control
                      as="select"
                      name="quantity"
                      value={articles[name].quantity}
                      onChange={(e) => changeQuantity(e, name)}>
                    {
                      QUANTITIES.map((quantity, index) => {
                        return <option key={index}>{quantity}</option>
                      })
                    }
                    </Form.Control>
                  </InputGroup>
                  <button 
                    className='btn center'
                    onClick={(e) => remove(e, name)}>
                      Supprimer
                  </button>
                </div>
              </li>
            )
          })
        }
        {
          isLoading && <Loader />
        }
      </ul>
      <h3>Total: : <span>{total}da</span></h3>
    </div>
  );
}