// Import modules
import React, { useState, useCallback, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaInstagram } from "react-icons/fa6";
import { HashLink as Link } from 'react-router-hash-link';

// Import style
import './NavBar.scss';

const DEVICE_TYPE = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile'
}

export default function NavBar () {

    const [deviceType, setDeviceType] = useState(document.body.clientWidth > 998 ? DEVICE_TYPE.DESKTOP : DEVICE_TYPE.MOBILE);

    const handleResize = useCallback(() => {
        if (document.body.clientWidth < 998) {
            if (deviceType !== DEVICE_TYPE.MOBILE) {
                setDeviceType(DEVICE_TYPE.MOBILE);
            }
        } else {
            if (deviceType !== DEVICE_TYPE.DESKTOP) {
                setDeviceType(DEVICE_TYPE.DESKTOP);
            }
        }
    }, [deviceType])
    
    useEffect(() => {
        handleResize();
    }, [handleResize])
    
    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [handleResize])

    return (
        <div id="navBar">
            <Navbar expand="lg">
            <Container>
                <Link to='/home'>
                    <Navbar.Brand >
                        <img
                            src="icon/logo-2.png"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            alt="Logo"
                        />{' '}
                        <span>Sérénité naturelle</span>
                    </Navbar.Brand>
                </Link>
                {
                    deviceType === DEVICE_TYPE.MOBILE &&
                    <div className='instagram center'>
                        <a href="https://www.instagram.com/serenitenaturelle/">
                            <FaInstagram />
                        </a>
                    </div>
                }
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className='navbar-light'>
                    <Nav className="me-auto">
                        <Link to='/home#products' className='center'>    
                            Produits
                        </Link>
                        <Link to='/contacts#contact' className='center'>
                            Contact
                        </Link>
                        <Link reloadDocument  to='/admin' className='center'>
                            Admin
                        </Link>
                    </Nav>  
                </Navbar.Collapse>
                {
                    deviceType === DEVICE_TYPE.DESKTOP &&
                    <div className='instagram center'>
                        <a href="https://www.instagram.com/serenitenaturelle/">
                            <FaInstagram />
                        </a>
                    </div>
                }
            </Container>
            </Navbar>
        </div>
    );
}