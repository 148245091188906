import React from 'react';
import './Spinner.scss';


export default function Spinner () {

    return (
        <div id='spinner' className='center'>
            <span className="loader"></span>
        </div>
    );
}
