// Import modules
import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Import components
import Loader from '../../../components/Loader/Loader';

// Import API
import { productAPI } from '../../../api/ProductApi';
import { bannerAPI } from '../../../api/BannerApi';

// Import constants
import { TOAST_PROPERTIES } from '../../../constants/toasts';

// Import style
import './AdminProducts.scss';

const ERROR_GET_PRODUCTS = 'Erreur lors de la récupération des produits';
const ERROR_GET_BANNERS = 'Erreur lors de la récupération des bannières';
const EXTENSION_ERROR = 'Seuls les formats jpg/jpeg/png sont autorisés';
const SIZE_ERROR = 'Image trop lourde (Le poids max est de 300 Ko)';


export default function AdminProducts({
  username, 
  password
}) {

  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [isBannersLoading, setIsBannersLoading] = useState(true);

  /**
   * Get all products
   */
  const getProducts = useCallback(() => {
    productAPI.getAll().then((res) => {
      const listProducts = res.data.map((product) => {
        return {
          id: product._id,
          order: product.order,
          name: product.name,
          price: product.price,
          reduction: product.reduction,
          description: product.description,
          banner: product.banner,
          image: product.smallImage,
          isNewImage: false,
          isNewProduct: false
        }
      }).sort(function (a, b) {
        return a.order - b.order;
      });
      setProducts(listProducts);
      setIsProductsLoading(false);
    }).catch((err) => {
      switch (err.response.data) {
        case "CREDENTIALS_ERROR":
          toast.error("Identifiant ou mot de passe incorrect", TOAST_PROPERTIES);
          break;
        default:
          toast.error(ERROR_GET_PRODUCTS, TOAST_PROPERTIES);
          break;
      }
      console.error(err);
      setIsProductsLoading(false);
    })
  }, []);

  /**
   * Add product
   */
  const addProduct = useCallback((
    order,
    name,
    price,
    reduction,
    description,
    banner,
    image
  ) => {
    productAPI.add(
      order,
      name,
      price,
      reduction,
      description,
      banner,
      image,
      { username: username, password: password }
    ).then(() => {
      toast.success('Produit ajouté !', TOAST_PROPERTIES);
    }).catch((err) => {
      switch (err.response.data) {
        case "EXTENSION_ERROR":
          toast.error("Extension invalide", TOAST_PROPERTIES);
          break;
        case "SIZE_ERROR":
          toast.error("Image trop lourde", TOAST_PROPERTIES);
          break;
        case "UPLOAD_OBJECT_ERROR":
          toast.error("La sauvegarde du média a échoué", TOAST_PROPERTIES);
          break;
        case "CREDENTIALS_ERROR":
          toast.error("Identifiant ou mot de passe incorrect", TOAST_PROPERTIES);
          break;
        default:
          toast.error("Une erreur s'est produite", TOAST_PROPERTIES);
          break;
      }
      console.error(err);
    });
  }, [username, password]);

  /**
   * Update product
   */
  const updateProduct = useCallback((
    id,
    order,
    name,
    price,
    reduction,
    description,
    banner,
    image,
    isNewImage,
  ) => {
    productAPI.update(
      id,
      order,
      name,
      price,
      reduction,
      description,
      banner,
      image,
      isNewImage,
      { username: username, password: password }
    ).then(() => {
      toast.success('Produits sauvegardés !', TOAST_PROPERTIES);
    }).catch((err) => {
      switch (err.response.data) {
        case "EXTENSION_ERROR":
          toast.error("Extension invalide", TOAST_PROPERTIES);
          break;
        case "SIZE_ERROR":
          toast.error("Image trop lourde", TOAST_PROPERTIES);
          break;
        case "UPLOAD_OBJECT_ERROR":
          toast.error("La sauvegarde du média a échoué", TOAST_PROPERTIES);
          break;
        case "DELETE_OBJECT_ERROR":
          toast.error("La suppression du média a échoué", TOAST_PROPERTIES);
          break;
        case "CREDENTIALS_ERROR":
          toast.error("Identifiant ou mot de passe incorrect", TOAST_PROPERTIES);
          break;
        default:
          toast.error("Une erreur s'est produite", TOAST_PROPERTIES);
          break;
      }
      console.error(err);
    });
  }, [username, password]);

  /**
   * Delete product
   * @param {String} id Id of the product
   */
  const deleteProduct = useCallback((id) => {
    productAPI.remove(id, { username: username, password: password }).then(() => {
      toast.success('Produit supprimé !', TOAST_PROPERTIES);
      const products_ = [...products];
      const ind = products_.findIndex((product) => product.id === id);
      if (ind > -1) {
        products_.splice(ind, 1);
        setProducts(products_);
      }
    }).catch((err) => {
      switch (err.response.data) {
        case "CREDENTIALS_ERROR":
          toast.error("Identifiant ou mot de passe incorrect", TOAST_PROPERTIES);
          break;
        default:
          toast.error("Une erreur s'est produite", TOAST_PROPERTIES);
          break;
      }
      console.error(err);
    });
  }, [username, password, products]);

  /**
   * Get all banner
   */
  const getBanners = useCallback(() => {
    bannerAPI.getAll().then((res) => {
      setBanners(res.data);
      setIsBannersLoading(false);
    }).catch((err) => {
      switch (err.response.data) {
        default:
          toast.error(ERROR_GET_BANNERS, TOAST_PROPERTIES);
          break;
      }
      console.error(err);
      setIsBannersLoading(false);
    })
  }, []);

  /**
   * Handle change
   * @param {Event} e
   * @param {Number} ind Index of the product
   */
  const handleChange = useCallback((e, ind) => {
    const products_ = [...products];
    products_[ind][e.target.name] = e.target.value;
    setProducts(products_);
  }, [products]);

  /**
   * Handle change image
   * @param {Event} e
   * @param {Number} ind Index of the product
   */
  const handleChangeImage = useCallback((e, ind) => {
    // Verify file
    if (e.target.files.length === 0) {
      return;
    }

    // Verify file size
    if (e.target.files[0].size > 1000000) {
      toast.error(SIZE_ERROR, TOAST_PROPERTIES);
      return;
    }

    // Verify file extension
    if (!["image/png", "image/jpeg"].includes(e.target.files[0].type)) {
      toast.error(EXTENSION_ERROR, TOAST_PROPERTIES);
      return;
    }

    // Save image
    const products_ = [...products];
    products_[ind].image = URL.createObjectURL(e.target.files[0]);
    products_[ind].isNewImage = true;
    setProducts(products_);
  }, [products]);

  /**
   * Delete a product
   * @param {Number} ind Index of the product
   */
  const handleDelete = useCallback((ind) => {
    deleteProduct(products[ind].id);
  }, [products, deleteProduct]);

  /**
   * Add a product
   */
  const handleAdd = useCallback(() => {
    const products_ = [...products];
    products_.push({
      id: '',
      order: products.length,
      name: '',
      price: '',
      reduction: '',
      description: '',
      banner: '',
      image: '',
      isNewImage: false,
      isNewProduct: true
    });
    setProducts(products_);
  }, [products]);

  /**
   * Reset form
   */
  const handleRefresh = useCallback((e) => {
    e.preventDefault();
    setIsProductsLoading(true);
    getProducts();
  }, [getProducts]);

  /**
   * Save product
   */
  const handleSave = useCallback((e, ind) => {
    e.preventDefault();
    const product = products[ind];
    if (product.isNewProduct) {
      addProduct(
        product.order,
        product.name,
        product.price,
        product.reduction,
        product.description,
        product.banner,
        product.image
      );
    } else {
      updateProduct(
        product.id,
        product.order,
        product.name,
        product.price,
        product.reduction,
        product.description,
        product.banner,
        product.image,
        product.isNewImage
      );
    }
  }, [
    products,
    addProduct,
    updateProduct
  ]);

  /**
   * Initialization
   */
  useEffect(() => {
    getProducts();
    getBanners();
  }, [
    getProducts, 
    getBanners
  ]);

  return (
    <div id="adminProducts" className='center'>
      <h1>... gestion des produits</h1>
      <div className='block'>

        <div className='buttons'>
          <button
            className="btn btn-grey"
            onClick={handleRefresh}>
            Rafraichir
          </button>

          <button
            className="btn btn-green"
            onClick={handleAdd}>
            Ajouter
          </button>
        </div>

        <div className='products-table'>
          <div className='products-header'>
            <div className='header center actions'>Actions</div>
            <div className='header center order'>Ordre</div>
            <div className='header center name'>Nom</div>
            <div className='header center price'>Prix</div>
            <div className='header center reduction'>Réduction</div>
            <div className='header center image'>Image</div>
            <div className='header center banner'>Bannière</div>
            <div className='header center description'>Description</div>
          </div>

          <div className='products-list'>
            {
              !isProductsLoading && !isBannersLoading && products.map((product, index) =>
                <div key={index} className='product-element'>
                  <div className='actions center'>
                    <button
                      className="btn btn-red center"
                      onClick={() => handleDelete(index)}>
                      Supprimer
                    </button>
                    <button
                      className="btn btn-green center"
                      onClick={(e) => handleSave(e, index)}>
                      Sauvegarder
                    </button>
                  </div>

                  <Form.Control
                    as="select"
                    name="order"
                    className="order"
                    value={products[index].order}
                    onChange={(e) => handleChange(e, index)}>
                    {
                      products.map((prod, indexProd) => {
                        return <option key={indexProd}>{indexProd}</option>
                      })
                    }
                  </Form.Control>

                  <Form.Control
                    type="text"
                    name="name"
                    className="name"
                    value={products[index].name}
                    onChange={(e) => handleChange(e, index)}
                    required />

                  <Form.Control
                    type="text"
                    name="price"
                    className="price"
                    value={products[index].price}
                    onChange={(e) => handleChange(e, index)}
                    required />

                  <Form.Control
                    type="text"
                    name="reduction"
                    className="reduction"
                    value={products[index].reduction}
                    onChange={(e) => handleChange(e, index)}
                    required />

                  <div className='image form-image'>
                    {
                      products[index].image !== '' && <img src={products[index].image} alt='preview' />
                    }
                    <Form.Label htmlFor={"file"+ index} className="label-file btn center">
                      Modifier
                    </Form.Label>
                    <Form.Control
                      id={"file"+ index}
                      className="input-file"
                      type="file"
                      onChange={(e) => handleChangeImage(e, index)} />
                  </div>

                  <Form.Control
                    as="select"
                    name="banner"
                    className="banner"
                    value={products[index].banner}
                    onChange={(e) => handleChange(e, index)}>
                      <option key={0} value=''></option>
                    {
                      banners.map((banner, indexBanner) => {
                        return <option key={indexBanner} value={banner.name}>{banner.name}</option>
                      })
                    }
                  </Form.Control>

                  <Form.Control
                    as="textarea"
                    name="description"
                    className='description'
                    rows={7}
                    value={products[index].description}
                    onChange={(e) => handleChange(e, index)}
                    required />
                </div>
              )
            }
            {
              (isProductsLoading || isBannersLoading) && <Loader />
            }
          </div>
        </div>

      </div>
    </div>
  );
}