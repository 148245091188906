// Import modules
import React from 'react';

// Import style
import './About.scss';

export default function About () {

  return (
    <div id="about" className='center'>
      <h1>... à propos de nous</h1>
      <div className='block'>
        <div className='block-left center'>
          <p>
            Bienvenue dans l'univers artisanal d'une jeune entrepreneuse passionnée qui a créé sa propre marque à Sidi Bel Abbes en Algerie. 
            <br /><br />
            Chez Sérénité naturelle, chaque création est le fruit d'un savoir-faire artisanal, alliant traditions locales et ingrédients naturels soigneusement sélectionnés. 
            <br /><br />
            Découvrez une gamme de savons, conçus avec amour pour offrir une expérience sensorielle exceptionnelle tout en préservant la beauté naturelle de votre peau.
          </p>
        </div>
        {/* <div className='block-right'>
          <video autoPlay loop muted src='/videos/story-insta.mp4' />
        </div> */}
      </div>
    </div>
  );
}