// Import modules
import React from 'react';

// Import style
import './Home.scss';

// Import components
import Presentation from '../../components/Presentation/Presentation';
import Products from './Products/Products';
import About from './About/About';
import Feedbacks from './Feedbacks/Feedbacks';

export default function Home () {
  return (
    <div id="home">
      <Presentation />
      <Products />
      <About />
      {/* <Feedbacks /> */}
    </div>
  );
}