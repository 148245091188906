import { urlApi, axiosInstance } from "./config";

const URL_CONTACT = urlApi + '/contact';

class ContactAPI {

    constructor (instance, url) {
        this.instance = instance;
        this.url = url;
    }

    sendMessage (name, phone, city, message) {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('city', city);
        formData.append('message', message);
        return axiosInstance.post(this.url, formData, config);
    }
}

export const contactAPI = new ContactAPI(axiosInstance, URL_CONTACT);
