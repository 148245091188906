// Import modules
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Import components
import Details from './Details/Details';
import ClientForm from './ClientForm/ClientForm';

// Import API
import { commandAPI } from '../../../api/CommandApi';

// Import constants
import { TOAST_PROPERTIES } from '../../../constants/toasts';

// Import store
import * as shoppingSelectors from '../../../store/selectors/shopping';
import * as shoppingActions from '../../../store/features/shopping';

// Import style
import './Summary.scss';


const INITIAL_FORM_DATA = {
  name: '',
  phone: '',
  city: '',
  message: ''
}

export default function Summary () {

  const navigate = useNavigate(); 
  const dispatch = useDispatch();
  const articles = useSelector(shoppingSelectors.getArticles);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  const handleRedirectHome = (section = '') => {
    navigate('/home');
    if (section === '') return;
    setTimeout(() => {
      const element = document.getElementById(section);
      const y = element.getBoundingClientRect().top + window.pageYOffset + 0;
      window.scrollTo({top: y, behavior: 'smooth'});
    }, 0);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // Vérification du formulaire
    setValidated(true);
    const isValid = e.currentTarget.checkValidity();
    if (!isValid) {
      e.stopPropagation();
      return;
    }

    // Envoi du formulaire
    commandAPI.sendCommand(
      formData.name, 
      formData.phone, 
      formData.city,
      formData.message,
      articles
    ).then(() => {
      toast.success('Commande envoyé !', TOAST_PROPERTIES);
      dispatch(shoppingActions.reset());
      handleRedirectHome();
    }).catch((err) => {
      toast.error("Erreur lors l'envoi de la commande", TOAST_PROPERTIES);
      console.error(err);
    });
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  return (
    <div id="summary" className='center'>
      <h1>... commander vos articles</h1>

      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}>

        <div className='blocks'>
          <div className='block center'>
            <Details />
          </div>

          <div className='block center'>
            <ClientForm 
              change={handleChange}
              name={formData.name}
              phone={formData.phone}
              city={formData.city}
              message={formData.message}
            />
          </div>
        </div>

        <div className='buttons'>
          <button 
            className="btn btn-home"
            onClick={() => handleRedirectHome('products')}>
            Revenir aux produits
          </button>
          <button 
            className="btn btn-green" 
            type="submit">
            Commander
          </button>
        </div>
      </Form>
    </div>
  );
}