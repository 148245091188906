import { SiNintendogamecube } from "react-icons/si";
import {
    FaTwitterSquare,
    FaFacebookSquare,
    FaYoutube,
    FaLinkedin,
    FaInstagram
} from "react-icons/fa";

import './Footer.scss';


export default function Footer () {

    return (
        <div id="footer">
            <img src="/img/footer-logo.png" alt='logo' />
        </div>
    )
}
