// Import modules
import React from 'react';
import { useSelector } from 'react-redux';
import { TiShoppingCart } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';

// Import store
import * as shoppingSelectors from '../../store/selectors/shopping';

// Import style
import './ShoppingBag.scss';

export default function ShoppingBag () {

    const navigate = useNavigate();
    const nbArticles = useSelector(shoppingSelectors.getNbArticles);

    const handleRedirectCommand = () => {
        navigate('/command');
        // scrollToSection(pSection);
    }

    return (
        <div id="shoppingBag">
            <TiShoppingCart  onClick={handleRedirectCommand} className='shopping-cart-icon' />
            <div className='shopping-cart-number'>{nbArticles}</div>
        </div>
    );
}