// Import modules
import React, { useState, useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Import components
import Loader from '../../../components/Loader/Loader';

// Import API
import { bannerAPI } from '../../../api/BannerApi';

// Import constants
import { TOAST_PROPERTIES } from '../../../constants/toasts';

// Import style
import './AdminBanners.scss';

const ERROR_GET_BANNERS = 'Erreur lors de la récupération des bannières';


export default function AdminBanners({
  username,
  password
}) {

  const [banners, setBanners] = useState([]);
  const [isBannersLoading, setIsBannersLoading] = useState(true);

  /**
   * Get all banner
   */
  const getBanners = useCallback(() => {
    bannerAPI.getAll().then((res) => {
      setBanners(res.data);
      setIsBannersLoading(false);
    }).catch((err) => {
      switch (err.response.data) {
        default:
          toast.error(ERROR_GET_BANNERS, TOAST_PROPERTIES);
          break;
      }
      console.error(err);
      setIsBannersLoading(false);
    })
  }, []);

  /**
   * Add banner
   */
  const addBanner = useCallback((
    name,
    colorBanner,
    colorReduction
  ) => {
    bannerAPI.add(
      name,
      colorBanner,
      colorReduction,
      { username: username, password: password }
    ).then(() => {
      toast.success('Bannière ajouté !', TOAST_PROPERTIES);
    }).catch((err) => {
      switch (err.response.data) {
        case "CREDENTIALS_ERROR":
          toast.error("Identifiant ou mot de passe incorrect", TOAST_PROPERTIES);
          break;
        default:
          toast.error("Une erreur s'est produite", TOAST_PROPERTIES);
          break;
      }
      console.error(err);
    });
  }, [username, password]);

  /**
   * Update banner
   */
  const updateBanner = useCallback((
    id,
    name,
    colorBanner,
    colorReduction
  ) => {
    bannerAPI.update(
      id,
      name,
      colorBanner,
      colorReduction,
      { username: username, password: password }
    ).then(() => {
      toast.success('Produits sauvegardés !', TOAST_PROPERTIES);
    }).catch((err) => {
      switch (err.response.data) {
        case "CREDENTIALS_ERROR":
          toast.error("Identifiant ou mot de passe incorrect", TOAST_PROPERTIES);
          break;
        default:
          toast.error("Une erreur s'est produite", TOAST_PROPERTIES);
          break;
      }
      console.error(err);
    });
  }, [username, password]);

  /**
   * Delete banner
   * @param {String} id Id of the banner
   */
  const deleteBanner = useCallback((id) => {
    bannerAPI.remove(
      id, 
      { username: username, password: password }
    ).then(() => {
      toast.success('Bannière supprimée !', TOAST_PROPERTIES);
      const banners_ = [...banners];
      const ind = banners_.findIndex((banner) => banner._id === id);
      if (ind > -1) {
        banners_.splice(ind, 1);
        setBanners(banners_);
      }
    }).catch((err) => {
      switch (err.response.data) {
        case "CREDENTIALS_ERROR":
          toast.error("Identifiant ou mot de passe incorrect", TOAST_PROPERTIES);
          break;
        default:
          toast.error("Une erreur s'est produite", TOAST_PROPERTIES);
          break;
      }
      console.error(err);
    });
  }, [
    username, 
    password, 
    banners
  ]);

  /**
   * Handle change
   * @param {Event} e
   * @param {Number} ind Index of the banner
   */
  const handleChange = useCallback((e, ind) => {
    e.preventDefault();
    const banners_ = [...banners];
    banners_[ind][e.target.name] = e.target.value;
    setBanners(banners_);
  }, [banners]);

  /**
   * Delete a banner
   * @param {Number} id Id of the banner
   */
  const handleDelete = useCallback((e, id) => {
    e.preventDefault();
    deleteBanner(id);
  }, [deleteBanner]);

  /**
   * Add a product
   */
  const handleAdd = useCallback((e) => {
    e.preventDefault();
    const banners_ = [...banners];
    banners_.push({
      _id: '',
      name: '',
      colorBanner: '',
      colorReduction: '',
      isNewBanner: true
    });
    setBanners(banners_);
  }, [banners]);

  /**
   * Reset form
   */
  const handleRefresh = useCallback((e) => {
    e.preventDefault();
    setIsBannersLoading(true);
    getBanners();
  }, [getBanners]);

  /**
   * Save product
   */
  const handleSave = useCallback((e, ind) => {
    e.preventDefault();
    const banner = banners[ind];
    if (banner.isNewBanner) {
      addBanner(
        banner.name,
        banner.colorBanner,
        banner.colorReduction
      );
    } else {
      updateBanner(
        banner._id,
        banner.name,
        banner.colorBanner,
        banner.colorReduction
      );
    }
  }, [
    banners,
    addBanner,
    updateBanner
  ]);

  /**
   * Initialization
   */
  useEffect(() => {
    getBanners();
  }, [
    getBanners
  ]);

  return (
    <div id="adminBanners" className='center'>
      <h1>... gestion des bannières</h1>
      <div className='block'>

        <div className='buttons'>
          <button
            className="btn btn-grey"
            onClick={handleRefresh}>
            Rafraichir
          </button>

          <button
            className="btn btn-green"
            onClick={handleAdd}>
            Ajouter
          </button>
        </div>

        <div className='banners-table'>
          <div className='banners-header'>
            <div className='header center actions'>Actions</div>
            <div className='header center name'>Nom</div>
            <div className='header center color-banner'>Couleur Bannière</div>
            <div className='header center color-reduction'>Couleur Réduction</div>
          </div>

          <div className='banners-list'>
            {
              !isBannersLoading && banners.map((banner, index) =>
                <div key={index} className='banner-element'>
                  <div className='actions center'>
                    <button
                      className="btn btn-red center"
                      onClick={(e) => handleDelete(e, banners[index]._id)}>
                      Supprimer
                    </button>
                    <button
                      className="btn btn-green center"
                      onClick={(e) => handleSave(e, index)}>
                      Sauvegarder
                    </button>
                  </div>

                  <Form.Control
                    type="text"
                    name="name"
                    className="name"
                    value={banners[index].name}
                    onChange={(e) => handleChange(e, index)}
                    required />

                  <Form.Control
                    type="text"
                    name="colorBanner"
                    className="color-banner"
                    value={banners[index].colorBanner}
                    onChange={(e) => handleChange(e, index)}
                    required />

                  <Form.Control
                    type="text"
                    name="colorReduction"
                    className="color-reduction"
                    value={banners[index].colorReduction}
                    onChange={(e) => handleChange(e, index)}
                    required />
                </div>
              )
            }
            {
              isBannersLoading && <Loader />
            }
          </div>
        </div>

      </div>
    </div>
  );
}