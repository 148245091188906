// Import modules
import React, { useEffect } from 'react';

// Import style
import './Contacts.scss';

// Import components
import Contact from './Contact/Contact';
import Presentation from './../../components/Presentation/Presentation';


export default function Contacts () {

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('contact');
      const y = element.getBoundingClientRect().top + window.pageYOffset + 0;
      window.scrollTo({top: y, behavior: 'smooth'});
    }, 0);
  }, []);

  return (
    <div id="home">
      <Presentation />
      <Contact />
    </div>
  );
}