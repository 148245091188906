// Import modules
import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

// Import API
import { contactAPI } from '../../../api/ContactApi';

// Import constants
import { TOAST_PROPERTIES } from '../../../constants/toasts';

// Import style
import './Contact.scss';


const INITIAL_FORM_DATA = {
  name: '',
  phone: '',
  city: '',
  message: ''
}

export default function Contact () {

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  const change = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const reset = (e) => {
    e.preventDefault();
    setFormData(INITIAL_FORM_DATA);
    setValidated(false);
  }

  const submit = (e) => {
    e.preventDefault();

    // Vérification du formulaire
    setValidated(true);
    const isValid = e.currentTarget.checkValidity();
    if (!isValid) {
      e.stopPropagation();
      return;
    }

    // Envoi du formulaire
    contactAPI.sendMessage(
      formData.name, 
      formData.phone,
      formData.city,
      formData.message
    ).then(() => {
      toast.success('Message envoyé !', TOAST_PROPERTIES);
      reset(e);
    }).catch((err) => {
      toast.error("Erreur lors l'envoi du message", TOAST_PROPERTIES);
      console.error(err);
    });
  }

  return (
    <div id="contact" className='center'>
      <h1>... nous contacter</h1>
      <div className='block'>
        <h2>Envoyer un message</h2>
        <Form
          noValidate
          validated={validated}
          onSubmit={submit}>

          <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="name"
              placeholder="Nom"
              value={formData.name}
              onChange={change}
              required/>
            <Form.Control.Feedback type="invalid">
              Merci de renseigner un nom
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="phone"
              placeholder="Numéro de téléphone"
              value={formData.phone}
              onChange={change}
              required/>
            <Form.Control.Feedback type="invalid">
              Merci de renseigner un noméro de téléphone
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="city"
              placeholder="Ville"
              value={formData.city}
              onChange={change}
              required/>
            <Form.Control.Feedback type="invalid">
              Merci de renseigner une ville
            </Form.Control.Feedback>
          </InputGroup>

          <InputGroup hasValidation>
            <Form.Control
                as="textarea"
                name="message"
                placeholder="Message"
                rows={7}
                value={formData.message}
                onChange={change}
                required/>
            <Form.Control.Feedback type="invalid">
                Merci de renseigner un email
            </Form.Control.Feedback>
          </InputGroup>

          <button 
              className="btn" 
              type="submit">
              Envoyer
            </button>

        </Form>
      </div>
    </div>
  );
}