import React from 'react';
import Spinner from '../Spinner/Spinner';

import './Loader.scss';


export default function Loader ({
    color = 'black',
    message = 'Récupération en cours',
    isLoaderIcon = true
}) {

    return (
        <div id='loader' className='center'>
            {message !== '' && <h2 style={{color: color}}>{message}</h2>}
            {isLoaderIcon && <Spinner />}
        </div>
    );
}
