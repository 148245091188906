// Import modules
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

// Import style
import './ClientForm.scss';



export default function ClientForm({
  change,
  name,
  phone,
  city,
  message
}) {

  return (
    <div id="clientForm" className='center'>
      <h2>... vos coordonnées</h2>

      <div className='form-details'>
        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="name"
            placeholder="Nom"
            value={name}
            onChange={change}
            required />
          <Form.Control.Feedback type="invalid">
            Merci de renseigner un nom
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup hasValidation>
          <Form.Control
            type="text"
            name="phone"
            placeholder="Numéro de téléphone"
            value={phone}
            onChange={change}
            required />
          <Form.Control.Feedback type="invalid">
            Merci de renseigner un numéro de téléphone
          </Form.Control.Feedback>
        </InputGroup>

        <InputGroup hasValidation>
            <Form.Control
              type="text"
              name="city"
              placeholder="Ville"
              value={city}
              onChange={change}
              required/>
            <Form.Control.Feedback type="invalid">
              Merci de renseigner une ville
            </Form.Control.Feedback>
          </InputGroup>

        <InputGroup>
          <Form.Control
            as="textarea"
            name="message"
            placeholder="Message"
            rows={7}
            value={message}
            onChange={change}/>
        </InputGroup>
      </div>
    </div>
  );
}