import { urlApi, axiosInstance } from "./config";

const URL_COMMAND = urlApi + '/command';

class CommandAPI {

    constructor (instance, url) {
        this.instance = instance;
        this.url = url;
    }

    sendCommand  (name, phone, city, message, articles) {
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('city', city);
        formData.append('message', message);
        formData.append('articles', JSON.stringify(articles));
        return axiosInstance.post(this.url, formData, config);
    }
}

export const commandAPI = new CommandAPI(axiosInstance, URL_COMMAND);
