import { urlApi, axiosInstance } from "./config";

const URL_PRODUCT = urlApi + '/product';

class ProductAPI {

    constructor (instance, url) {
        this.instance = instance;
        this.url = url;
    }

    async add (
        order,
        name,
        price,
        reduction,
        description,
        banner,
        image,
        auth
    ) {
        const config = { 
            headers: { 'Content-Type': 'multipart/form-data' },
            auth: auth
        };
        const formData = new FormData();
        formData.append('order', order);
        formData.append('name', name);
        formData.append('price', price);
        formData.append('reduction', reduction);
        formData.append('description', description);
        formData.append('banner', banner);
        if (image) {
            const base64Response = await fetch(image);
            const blob = await base64Response.blob();
            formData.append('image', blob);
        }
        return axiosInstance.post(this.url, formData, config);
    }

    get (id) {
        return axiosInstance.get(this.url + '?id=' + id);
    }

    getAll () {
        return axiosInstance.get(this.url + '/all');
    }

    remove (id, auth) {
        const config = { auth: auth };
        return axiosInstance.delete(this.url, { data: { id: id }, ...config });
    }

    async update (
        id,
        order,
        name,
        price,
        reduction,
        description,
        banner,
        image,
        isNewImage,
        auth
    ) {
        const config = { 
            headers: { 'Content-Type': 'multipart/form-data' },
            auth: auth
        };
        const formData = new FormData();
        formData.append('order', order);
        formData.append('name', name);
        formData.append('price', price);
        formData.append('reduction', reduction);
        formData.append('description', description);
        formData.append('banner', banner);
        if (isNewImage) {
            const base64Response = await fetch(image);
            const blob = await base64Response.blob();
            formData.append('image', blob);
        }
        return axiosInstance.put(this.url + '?id=' + id, formData, config);
    }
}

export const productAPI = new ProductAPI(axiosInstance, URL_PRODUCT);
