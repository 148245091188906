// Import modules
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaInfoCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { LuShoppingBasket } from "react-icons/lu";
import { FaPlus } from "react-icons/fa";
import { toast } from 'react-toastify';

// Import components
import Loader from '../../../components/Loader/Loader';

// Import store
import * as globalsSelector from '../../../store/selectors/globals';
import * as shoppingActions from '../../../store/features/shopping';
import * as globalsActions from '../../../store/features/globals';

// Import API
import { productAPI } from '../../../api/ProductApi';
import { bannerAPI } from '../../../api/BannerApi';

// Import constants
import { TOAST_PROPERTIES } from '../../../constants/toasts';

// Import style
import './Products.scss';

const NB_ELEMENTS_BY_PAGE = 12;

export default function Products() {

  const dispatch = useDispatch();
  const section = useSelector(globalsSelector.getSection);

  const [nbPerLine, setNbPerLine] = useState(4);
  const [productWidth, setProductWidth] = useState('25%');
  const [numPage, setNumPage] = useState(0);
  const [descriptionState, setDescriptionState] = useState({});
  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState({});
  const [nbPages, setNbPages] = useState(0);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [isBannersLoading, setIsBannersLoading] = useState(true);

  /**
   * Resize
   */
  const resize = useCallback(() => {
    let windowSize = (section !== '') ? document.body.clientWidth - 360 : document.body.clientWidth - 60;

    let nbColumn, width;
    if (windowSize < 400) {
      nbColumn = 1;
      width = '100%';
    } else if (windowSize > 400 && windowSize < 700) {
      nbColumn = 2;
      width = '50%';
    } else if (windowSize > 700 && windowSize < 1300) {
      nbColumn = 3;
      width = '33%';
    } else {
      nbColumn = 4;
      width = '25%';
    }

    if (nbColumn !== nbPerLine) {
      setNbPerLine(nbColumn);
      setProductWidth(width);
      setNumPage(0);
    }
  }, [section, nbPerLine])

  /**
   * Get all products
   */
  const getProducts = useCallback(() => {
    productAPI.getAll().then((res) => {
      setProducts(res.data);
      setDescriptionState(res.data.reduce((acc, curr) => {
        acc[curr.name] = false;
        return acc
      }, {}));
      setNbPages(Math.ceil(Object.keys(res.data).length / NB_ELEMENTS_BY_PAGE));
      setIsProductsLoading(false);
    }).catch((err) => {
      toast.error("Erreur lors de la récupération des produits, rafraichir la page", TOAST_PROPERTIES);
      console.error(err);
      setIsProductsLoading(false);
    })
  }, []);

  /**
   * Get all banners
   */
  const getBanners = useCallback(() => {
    bannerAPI.getAll().then((res) => {
      const banners_ = res.data.reduce((acc, curr) => {
        acc[curr.name] = curr;
        return acc
      }, {});
      setBanners(banners_);
      setIsBannersLoading(false);
    }).catch((err) => {
      toast.error("Erreur lors de la récupération des bannières", TOAST_PROPERTIES);
      console.error(err);
      setIsBannersLoading(false);
    })
  }, []);

  /**
   * Scroll to products
   */
  const scrollToProducts = useCallback(() => {
    const element = document.getElementById('products');
    const y = element.getBoundingClientRect().top + window.pageYOffset + 0;
    window.scrollTo({top: y, behavior: 'smooth'});
  }, []);

  /**
   * Handle previous page
   */
  const handlePreviousPage = useCallback(() => {
    if (numPage > 0) {
      let page = numPage - 1;
      setNumPage(page);
      scrollToProducts();
    }
  }, [numPage, scrollToProducts]);

  /**
   * Handle next page
   */
  const handleNextPage =  useCallback(() => {
    if (numPage < nbPages - 1) {
      let page = numPage + 1;
      setNumPage(page);
      scrollToProducts();
    }
  }, [numPage, nbPages, scrollToProducts]);

  /**
   * Handle change page
   * @param {Number} page
   */
  const handleChangePage = useCallback((page) => {
    setNumPage(page);
    scrollToProducts();
  }, [scrollToProducts]);

  /**
   * Handle open description
   * @param {String} pProductName 
   */
  const handleOpenDescription = useCallback((pProductName) => {
    setDescriptionState({ ...descriptionState, [pProductName]: true });
  }, [descriptionState]);

  /**
   * Handle close description
   * @param {String} pProductName 
   */
  const handleCloseDescription = useCallback((pProductName) => {
    setDescriptionState({ ...descriptionState, [pProductName]: false });
  }, [descriptionState]);

  /**
   * Handle add article
   * @param {String} pProductName
   */
  const handleAddArticle = useCallback((pProductName) => {
    dispatch(shoppingActions.add(pProductName));
  }, [dispatch]);

  /**
   * Handle open image
   * @param {String} image
   */
  const handleOpenImage = useCallback((image) => {
    dispatch(globalsActions.setImageViewerUrl(image));
  }, [dispatch]);

  /**
   * Initialization
   */
  useEffect(() => {
    resize();
    getProducts();
    getBanners();
  }, [
    resize,
    getProducts,
    getBanners
  ]);

  /**
   * Detect resize
   */
  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [resize]);

  return (
    <div id="products" className='center'>

      <h1>... nos produits</h1>

      {/* Products */}
      <div className='block'>
          {
            !isProductsLoading && !isBannersLoading && products.sort(function (a, b) {
              return a.order - b.order;
            }).map((prod, index) =>
              <div 
                key={index} 
                className='product-container'
                style={{
                  width: productWidth,
                  display: (
                    index >= numPage * NB_ELEMENTS_BY_PAGE 
                    && index < numPage * NB_ELEMENTS_BY_PAGE + NB_ELEMENTS_BY_PAGE) ? '' : 'none',
                  marginTop: nbPerLine === 1 ? '10px' : '0px'
                }}>
                <div 
                  className='product center' 
                  style={{ 
                    height: nbPerLine === 1 && prod.banner === '' ? '100%' : '88%',
                    top: nbPerLine === 1 && prod.banner === '' ? '0%' : '10%'
                  }}>

                  {/* Image */}
                  <img 
                    src={prod.smallImage} 
                    onClick={() => handleOpenImage(prod.largeImage)}
                    alt='logo' />

                  {/* Name */}
                  <div className='product-name center'>{prod.name}</div>

                  {/* Basket */}
                  <div className='basket' onClick={() => handleAddArticle(prod.name)}>
                    <LuShoppingBasket className='basket-icon' />
                    <FaPlus className='basket-icon-plus' />
                  </div>

                  {/* Prices */}
                  {/* <FaInfoCircle onClick={() => handleOpenDescription(prod.name)} className='info-icon' /> */}

                  {/* Prices */}
                  {
                    prod.reduction === 0 && <div className='product-price center'>{prod.price}da</div>
                  }
                  {
                    prod.reduction !== 0 && <div className='product-price product-price-old center'>
                      {prod.price}da
                    </div>
                  }
                  {
                    prod.reduction !== 0 && prod.banner !== '' &&
                      <div 
                        style={{ backgroundColor: banners[prod.banner].colorReduction}}
                        className='product-price product-price-new center'>
                          {prod.price - prod.reduction}da
                      </div>
                  }

                  {/* Description */}
                  <div
                    className='product-description'
                    style={{ display: descriptionState[prod] ? 'block' : 'none' }}>
                    <div className='close-description'>
                      {prod.name}
                      <MdClose onClick={() => handleCloseDescription(prod.name)} />
                    </div>
                    <p>{prod.description}</p>
                  </div>
                </div>

                {/* Overlay Events */}
                {
                  prod.banner && 
                    <div 
                      style={{ backgroundColor: banners[prod.banner].colorBanner}}
                      className='product-overlay'>
                        <div className='product-overlay-text center'>
                          {banners[prod.banner].name}
                        </div>
                    </div>
                }
              </div>
            )
          }
      </div>

      {/* Pages */}
      <div className='pages'>
        {
          numPage > 0 && 
            <img 
              src='/img/arrow.png' 
              alt='logo' 
              className='arrows arrow-left'
              onClick={handlePreviousPage} />
        }
        {
          Array(nbPages).fill(0).map((value, index) =>
            <div
              key={index}
              style={{ backgroundColor: (numPage === index) ? 'rgb(227 143 150)' : 'rgb(255 203 208)' }}
              className='dot'
              onClick={() => handleChangePage(index)}>
            </div>
          )
        }
        {
          nbPages > 0 && numPage !== nbPages - 1 &&
            <img
              src='/img/arrow.png' 
              alt='logo' 
              className='arrows arrow-right'
              onClick={handleNextPage} />
        }
      </div>

      {/* Loader */}
      {(isProductsLoading || isBannersLoading) && <Loader message="Récupération des produits en cours" />}
    </div>
  );
}