// Import modules
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

// Import style
import './Presentation.scss';

export default function Presentation () {

  return (
    <div id="presentation">
      <div className='banner'/>
      <div className='home-section center'>
        <div className='block'>
          <img src='/img/logo-icon2.png' alt='Sérénité naturelle logo' />
          <p>
          "Trouver la sérénité dans la beauté naturelle qui nous entoure, c'est découvrir l'harmonie qui réside dans chaque souffle du vent et chaque murmure de la nature."
          </p>
          <div className='buttons'>
          <Link to='/home#products'>
            <button className='products-btn'>Voir nos produits</button>
          </Link>
          <Link to='/contacts'>
            <button className='contact-btn'>Nous contacter</button>
          </Link>
          </div>
        </div>
      </div>

    </div>
  );
}