import { createSlice } from '@reduxjs/toolkit'
 
const { actions, reducer } = createSlice({
    name: 'shopping',
    initialState: { 
        articles: {}
    },
    reducers: {
        add: (state, action) => {
            const articles = { ...state.articles}
            if (articles[action.payload]) {
                articles[action.payload].quantity++;
            } else {
                articles[action.payload] = {  
                    quantity: 1 
                };
            }
            state.articles = articles;
        },
        setQuantity: (state, action) => {
            const articles = { ...state.articles}
            articles[action.payload.name] = {  
                quantity: action.payload.quantity 
            };
            state.articles = articles;
        },
        remove: (state, action) => {
            const articles = { ...state.articles}
            delete articles[action.payload];
            state.articles = articles;
        },
        reset: (state, action) => {
            state.articles = {};
        }
    },
});

export const { 
    add,
    setQuantity,
    remove,
    reset
} = actions;

export default reducer;