// Import modules
import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaBox, FaFlag } from "react-icons/fa";
import { MdLocalOffer } from "react-icons/md";

// Import pages
import Presentation from '../../components/Presentation/Presentation';
import AdminProducts from './AdminProducts/AdminProducts';
import AdminBanners from './AdminBanners/AdminBanners';

// Import constants
import { TOAST_PROPERTIES } from '../../constants/toasts';

// Import API
import { authAPI } from '../../api/AuthApi';

// Import style
import './Admin.scss';

const ERROR_LOGIN = "Identifiant ou mot de passe incorrect";

export default function Admin () {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState('');

  /**
   * Login
   */
  const login = useCallback(() => {
    const username_ = prompt('Username:','');
    const password_ = prompt('Password:','');
    authAPI.login(username_, password_).then((res) => {
      setUsername(username_);
      setPassword(password_);
    }).catch((err) => {
      if (!err.response) return;
      switch (err.response.data) {
        case "CREDENTIALS_ERROR":
        default:
          toast.error(ERROR_LOGIN, TOAST_PROPERTIES);
          break;
      }
      console.error(err);
    });
  }, []);

  /**
   * Initialization
   */
  useEffect(() => {
    login();
  }, [login]);

  /**
   * Scroll to menu
   */
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById('menu');
      if (!element) return;
      const y = element.getBoundingClientRect().top + window.pageYOffset + 0;
      window.scrollTo({top: y, behavior: 'smooth'});
    }, 0);
  }, []);

  return (
    <div id="admin">
      <Presentation />

      {/* Menu */}
      {
        mode === '' && username !== '' && password !== '' && 
          <div id='menu'>
            <h1>... Administration du site</h1>
            <div className="block">
              <button
                className="btn center"
                onClick={() => setMode('products')}>
                Gestion des produits
                <FaBox />
              </button>
              <button
                className="btn center"
                onClick={(e) => setMode('banners')}>
                Gestion des bannières
                <MdLocalOffer />
              </button>
            </div>
          </div>
      }

      {/* Products */}
      {
        mode === 'products' && <AdminProducts username={username} password={password} />
      }

      {/* Banners */}
      {
        mode === 'banners' && <AdminBanners username={username} password={password} />
      }

    </div>
  );
}