import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineClose } from "react-icons/md";

// Import store
import * as globalsActions from '../../store/features/globals';
import * as globalsSelectors from '../../store/selectors/globals';

// Import style
import './ImageViewer.scss';

export default function ImageViewer () {
    const dispatch = useDispatch();
    const imageUrl = useSelector(globalsSelectors.getImageViewerUrl);
    
    const handleClose = () => {
        dispatch(globalsActions.setImageViewerUrl(''));
    }

    if (imageUrl !== '') {
        return (
            <div id='imageViewer' className='center'>
                <button onClick={handleClose} className='center'>
                    <MdOutlineClose />
                </button>
                <img src={imageUrl} alt="profil" />
            </div>
        )
    }
}
