import { urlApi, axiosInstance } from "./config";

class BannerAPI {

    constructor (instance, url) {
        this.instance = instance;
        this.url = url;
    }

    async add (
        name,
        colorBanner,
        colorReduction,
        auth
    ) {
        const config = {
            auth: auth
        };
        const formData = {
            name: name,
            colorBanner: colorBanner,
            colorReduction: colorReduction
        }
        return axiosInstance.post(this.url, formData, config);
    }

    get (id) {
        return axiosInstance.get(this.url + '?id=' + id);
    }

    getAll () {
        return axiosInstance.get(this.url + '/all');
    }

    remove (id, auth) {
        const config = { auth: auth };
        return axiosInstance.delete(this.url, { data: { id: id }, ...config });
    }

    update (
        id,
        name,
        colorBanner,
        colorReduction,
        auth
    ) {
        const config = {
            auth: auth
        };
        const formData = {
            name: name,
            colorBanner: colorBanner,
            colorReduction: colorReduction
        }
        return axiosInstance.put(this.url + '?id=' + id, formData, config);
    }
}

export const bannerAPI = new BannerAPI(axiosInstance, urlApi + '/banner');
