// Import modules
import React from 'react';

// Import components
import Summary from './Summary/Summary';

// Import style
import './Command.scss';

export default function Command () {
  return (
    <div id="command">
        <Summary />
    </div>
  );
}